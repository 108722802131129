import React from 'react';
import './App.css';
import waveSVG from './svg/vague.svg';
import needImage from './image/besoin.jpg';
import projectImage from './image/projet.jpg';
import collaborationImage from './image/collab.jpg';
import monicaImage from './image/monica.png';
import papillonSVG from './svg/papillon.svg';
import circleSVG from './svg/circle.svg';
import phoneSVG from './svg/phone.svg';
import mailSVG from './svg/mail.svg';
import linkedinImage from './image/linkedin.png';

function App() {
  return (
    <div className="App">
      <div className="section light">
        <div className='greetings'>
          <div className='italic'>Bonjour,</div>
          <div>Je m'appelle Monica</div>
          <div>!</div>
        </div>
        <div className='text-intro'>
          <p>
            Je suis une étudiante en dernière année de Master chez <b>MyDigitalSchool Lyon</b>.
            <br />
            <b>Je suis à la recherche d'un projet à réaliser en collaboration avec vous</b>.
          </p>
        </div>
        <div className='intro-end'>Travaillons<br />ensemble !</div>
      </div>

      <div className="section light long-section">
        <div>
          <h1>En résumé</h1>
          <img src={waveSVG} alt="vague" className="wave" />
        </div>
        <div className='center-resume'>
          <div className='bloc-resume'>
            <div className='theme-resume'>
              <img src={needImage} className='image-resume' />
              <h2>Un besoin</h2>
              <div className='line'></div>
              <div className='text-resume'>
                Envie de créer ou d'améliorer un site web pour votre entreprise, votre association ou votre projet personnel ?
              </div>
            </div>
            <div className='theme-resume'>
              <img src={projectImage} className='image-resume' />
              <h2>Mon objectif</h2>
              <div className='line'></div>
              <div className='text-resume'>
                Valider mon master en développement web et web mobile.
              </div>
            </div>
            <div className='theme-resume'>
              <img src={collaborationImage} className='image-resume' />
              <h2>Une collaboration</h2>
              <div className='line'></div>
              <div className='text-resume'>
                Vous avez un projet, je vous propose de le réaliser ensemble.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section dark long-section-two">
        <div className='bloc-description'>
          <div className='text-description'>
            <p>
              Étudiante spécialisée en développement web,<br />
              je recherche un client pour <span class="gras-blanc">un projet de création de site web</span>. <br />
              Ce projet revêt une importance particulière, car il contribuera à la <span class="gras-blanc">validation de mon master</span>.
            </p>
            <p>
              Voici comment se déroulera notre collaboration :
            </p>
            <ul>
              <li>Premier échange pour faire connaissance</li>
              <li>Première réunion : <span class="gras-blanc">Compréhension approfondie de votre besoin</span> avec une analyse de votre environnement et de vos contraintes</li>
              <li>Rédaction d'un <span class="gras-blanc">cahier des charges</span> détaillé qui vous sera transmis</li>
              <li>Validation du cahier des charges et démarrage du développement</li>
              <li>Mise en place d'outils pour la gestion de votre projet avec des <span class="gras-blanc">échanges réguliers</span> pour vous tenir informé(e)</li>
              <li>Présentation de la première version de votre projet</li>
              <li>Adaptation si besoin et finalisation de la mise en place de votre projet</li>
              <li>Réunion de clôture</li>
            </ul>
          </div>
          <div>
            <img src={monicaImage} className='image-description' />
          </div>
        </div>
      </div>


      <div className="section light short-section">
        <div className='greetings'>
          <div className='italic'>
            Alors,
          </div>
          Prêt à se lancer ?
        </div>
      </div>

      <div className="section dark">
        <div className='bloc-contact'>
          <div className='first-bloc-contact'>
            <div className='accroch-contact'>
              Contactez-moi pour qu'on puisse donner vie à votre projet
            </div>
            <img src={papillonSVG} className='papillon' />
          </div>
          <div className='first-bloc-contact'>
            <div className='line-light'></div>
            <div className='name'>MONICA WALENTEK</div>
          </div>

          <div className='third-bloc-contact'>
            <div>
              <div className='info-contact-bloc'>
                <div className='picture-contact'>
                  <img src={circleSVG} className='circle' />
                  <img src={phoneSVG} className='phone' />
                </div>
                <div>
                  <p>TÉLÉPHONE</p>
                  <a href="tel:+336 12 88 44 39">06 12 88 44 39</a>
                </div>
              </div>
              <div className='info-contact-bloc'>
                <div className='picture-contact'>
                  <img src={circleSVG} className='circle' />
                  <img src={mailSVG} className='mail' />
                </div>
                <div>
                  <p>EMAIL</p>
                  <a href="mailto:monica.walentek@gmail.com">monica.walentek@gmail.com</a>
                </div>
              </div>
              <div className='info-contact-bloc'>
                <div className='picture-contact'>
                  <img src={circleSVG} className='circle' />
                  <img src={linkedinImage} className='linkedin' />
                </div>
                <div>
                  <p>LINKEDIN</p>
                  <a href="https://www.linkedin.com/in/monica-walentek/">Monica Walentek</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
}

export default App;
